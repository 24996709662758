import styled from 'styled-components';
import {
	media,
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	display: grid;
	justify-items: center;
	padding: 0 15px;
	text-align: center;
	margin-top: ${props => (props.isHomePage ? '0' : '50px')};
	color: ${setColors.lightBlack};
	.blue-title {
		color: ${setColors.themeBlue};
	}
	div:nth-of-type(2) {
		padding-top: 0rem;
	}

	h2,
	h1 {
		font-family: ${setFontFamily.black};
		color: ${props => (props.blueTitle ? `${setColors.themeBlue}` : '')};
		font-weight: ${props =>
			props.isHomePage ? setFontWeight.bold : setFontWeight.extraBold};
		margin: 0 auto;
		margin-bottom: ${props =>
			props.setHeadersGap
				? `${props.setHeadersGap}px`
				: props.largeHeading
				? `20px`
				: `8px`};
		font-size: ${props =>
			props.isHomePage
				? setFontSize.tinyMedium
				: props.largeHeading
				? setFontSize.mediumSmall
				: props.isDemo
				? setFontSize.normalLarge
				: setFontSize.large};
		line-height: ${props => (props.largeHeading ? '1.11' : '1.2')};
		letter-spacing: -1.5px;
		text-align: center;
		max-width: ${props => (props.isHomePage ? '1200px' : '800px')};
	}
	p {
		font-size: ${props =>
			props.isHomePage
				? setFontSize.tiny
				: props.largeHeading
				? setFontSize.mediumTiny
				: setFontSize.veryTiny};
		max-width: 900px;
		margin: ${props =>
				props.setHeadersGap ? `${props.setHeadersGap}px` : `20px`}
			0;
		line-height: 1.3;
	}

	${media.tablet`
		h2,h1{
			font-size: ${props =>
				props.isHomePage
					? setFontSize.medium
					: props.largeHeading
					? setFontSize.veryLarge
					: props.isDemo
					? setFontSize.mediumSmall
					: setFontSize.mediumSmall};
		}
	`}

	${media.desktop`
		h2,h1 {
			font-size: ${setFontSize.mediumLarge} !important;	
		}
		p{
			font-size: ${props =>
				props.isHomePage ? setFontSize.tiny : setFontSize.mediumTiny};
		}
	`};

	${media.large`
		h2,h1{
			font-size: ${props =>
				props.isHomePage
					? setFontSize.tinyLarge
					: props.isDemo
					? setFontSize.normalLarge
					: setFontSize.mediumLarge} !important;
		}
		p{
			font-size: ${props =>
				props.isHomePage ? setFontSize.tiny : setFontSize.verySmall} !important;
		}
	`}
`;

export const ButtonWrapper = styled.div`
	margin-bottom: 60px;
	margin-top: 30px;
	.learnMoreButton {
		${mediaQuery.mobileXL`
			padding: 16px 61px;
		`}
	}
`;
