import React from 'react';
import Button from '../../global/Button';
import RichTextRenderer from '../../global/RichTextRenderer';
import * as S from './styles';
const SectionTextBox = ({
	title,
	subtitle,
	button,
	link,
	blueTitle,
	largeHeading,
	setTitleHtml,
	richTextContent,
	section2BlueTitlePart,
	isHeroHeading,
	isDemo,
	setHeadersGap,
	btnText = 'Learn more',
	isHomePage,
}) => {
	return (
		<S.Wrapper
			blueTitle={blueTitle}
			largeHeading={largeHeading}
			setHeadersGap={setHeadersGap}
			isDemo
			isHomePage={isHomePage}
		>
			<div>
				{setTitleHtml ? (
					isHeroHeading ? (
						<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
					) : (
						<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
					)
				) : (
					<h2>
						{title}
						{section2BlueTitlePart && (
							<>
								<br />
								<span className="blue-title">{section2BlueTitlePart}</span>
							</>
						)}
					</h2>
				)}
				{subtitle && <p>{subtitle}</p>}
				{richTextContent && <RichTextRenderer content={richTextContent} />}
			</div>

			{button && (
				<S.ButtonWrapper>
					<Button to={link} className="learnMoreButton">
						{btnText}
					</Button>
				</S.ButtonWrapper>
			)}
		</S.Wrapper>
	);
};

export default SectionTextBox;
